import { useDisclosure } from "@einride/hooks"
import {
  Alert,
  Box,
  Icon,
  SecondaryButton,
  Table,
  TableSkeleton,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@einride/ui"
import styled from "@emotion/styled"
import { Cog } from "@emotion-icons/boxicons-solid"
import { TopRightFlexDiv } from "components/FlexDiv"
import { LastSeenWidget } from "components/LastSeenWidget"
import { Logout } from "components/LogoutButton"
import { MobileSearchBar } from "components/MobileSearchBar"
import { SettingsSheet } from "components/SettingsSheet"
import { StyledTitle1 } from "components/StyledTitle1"
import FuzzySearch from "fuzzy-search"
import { RemoteInterface } from "gen/einride/rd_operator_interface/v1/remote_interface_pb"
import { useListRemoteInterfaces } from "lib/api/hooks/useListRemoteInterfacses"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

export const StyledSecondaryButton = styled(SecondaryButton)`
  @media (max-width: 530px) {
    font-size: 11px;
  }
`
const StyledTd = styled(Td)`
  text-decoration: underline;
  cursor: pointer;
`
const StyledTable = styled(Table)`
  @media (max-width: 530px) {
    font-size: 12px;
    table-layout: fixed;
    width: 100%;
  }
`

export const StationView: React.FC = () => {
  const navigate = useNavigate()
  const [stations, setStations] = useState<RemoteInterface[] | undefined>(undefined)

  const { isOpen: settingsIsOpen, handlers: settingsHandlers } = useDisclosure(false)
  const { isOpen: alertIsOpen, handlers: alertHandlers } = useDisclosure(false)
  const [searchTerm, setSearchTerm] = useState("")
  const searcher = new FuzzySearch(stations || [], ["location", "displayName"])
  const filteredStations: RemoteInterface[] = searcher.search(searchTerm)
  const [sortingOrderAsc, setSortingOrderAsc] = useState<boolean>(true)
  const [sortedColumn, setSortedColumn] =
    useState<keyof Pick<RemoteInterface, "name" | "displayName" | "location">>("displayName")

  const remoteInterfacesQueryEu = useListRemoteInterfaces({
    region: "EU",
    pageSize: 1000,
  })
  const remoteInterfacesQueryUs = useListRemoteInterfaces({
    region: "US",
    pageSize: 1000,
  })

  useEffect(() => {
    setStations(remoteInterfacesQueryEu.data.concat(remoteInterfacesQueryUs.data))
  }, [remoteInterfacesQueryEu.data, remoteInterfacesQueryUs.data])

  useMemo(() => {
    if (sortingOrderAsc) {
      filteredStations?.sort((a, b) =>
        (a?.[sortedColumn] ?? "").localeCompare(b[sortedColumn] ?? "", undefined, {
          numeric: true,
          sensitivity: "base",
        }),
      )
    } else {
      filteredStations?.sort((a, b) =>
        (b?.[sortedColumn] ?? "").localeCompare(a[sortedColumn] ?? "", undefined, {
          numeric: true,
          sensitivity: "base",
        }),
      )
    }
  }, [filteredStations, sortedColumn, sortingOrderAsc])

  const handleSearchChange = (searchString: string): void => {
    setSearchTerm(searchString)
  }
  const handleSort = (
    column: keyof Pick<RemoteInterface, "name" | "displayName" | "location">,
  ): void => {
    if (column === sortedColumn) {
      setSortingOrderAsc(!sortingOrderAsc)
    } else {
      setSortingOrderAsc(true)
    }
    setSortedColumn(column)
  }

  const th = (
    <Thead>
      <Tr color="secondary">
        <Th style={{ cursor: "pointer" }} scope="col" onClick={() => handleSort("displayName")}>
          Station
          <Icon
            style={{ paddingLeft: "0.5em", paddingRight: 0 }}
            name={sortingOrderAsc ? "chevronUp" : "chevronDown"}
            hidden={sortedColumn !== "displayName"}
          />
        </Th>
        <Th style={{ cursor: "pointer" }} scope="col" onClick={() => handleSort("location")}>
          Location
          <Icon
            style={{ paddingLeft: "0.5em", paddingRight: 0 }}
            name={sortingOrderAsc ? "chevronUp" : "chevronDown"}
            hidden={sortedColumn !== "location"}
          />
        </Th>
        <Th scope="col">Connected</Th>
        <Th scope="col">Status</Th>
      </Tr>
    </Thead>
  )

  return (
    <Box display="flex">
      <TopRightFlexDiv className="stationView">
        <StyledSecondaryButton rightIcon={<Icon name="warning" />} onClick={alertHandlers.open}>
          Using 24.1 or below?
        </StyledSecondaryButton>
        <StyledSecondaryButton onClick={settingsHandlers.open} rightIcon={<Cog />}>
          Settings
        </StyledSecondaryButton>
        <Logout>Log out</Logout>
      </TopRightFlexDiv>
      <Box
        alignItems="flex-start"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        padding="sm"
        height="100vh"
        width="100vw"
      >
        <StyledTitle1 className="extra">Select station</StyledTitle1>
        <Box padding="sm" height="100vh" width="100%">
          <MobileSearchBar
            wrapperProps={{ width: "100%" }}
            aria-label="searchStation"
            placeholder="Search stations"
            value={searchTerm}
            onInputChange={handleSearchChange}
          />
          {!remoteInterfacesQueryEu.isLoading && !remoteInterfacesQueryUs.isLoading ? (
            <StyledTable>
              {th}
              <Tbody>
                {(filteredStations.length > 0 &&
                  filteredStations.map((station) => {
                    return (
                      <Tr key={station.name}>
                        <StyledTd scope="row" onClick={() => navigate(`/${station.name}/vehicles`)}>
                          {station.displayName}
                        </StyledTd>
                        <Td>{station.location}</Td>
                        <Td>{station?.status?.connectedVehicle?.split("/").at(-1) || "Idle"}</Td>
                        <Td>
                          <LastSeenWidget time={station.status?.updateTime} />
                        </Td>
                      </Tr>
                    )
                  })) || (
                  <Tr>
                    <Td scope="row"> No stations found </Td>
                  </Tr>
                )}
              </Tbody>
            </StyledTable>
          ) : (
            <TableSkeleton columns={4} rows={5} thead={th} />
          )}
        </Box>
      </Box>
      <SettingsSheet handlers={settingsHandlers} isOpen={settingsIsOpen} />
      <Alert
        closeHandler={alertHandlers.close}
        isOpen={alertIsOpen}
        title="Select Operator Interface Version"
        description="You are currently using the v2 version of the operator interface,
         this version is only usuable with release 25 or later.
         If you are using an earlier version please switch to operator interface v1."
        primaryAction={{
          children: "Take me to v1",
          onClick: () => {
            window.location.href = "https://web.ro.einride.systems/"
          },
        }}
        secondaryAction={{ children: "Continue using v2", onClick: alertHandlers.close }}
      />
    </Box>
  )
}
